import { Box } from '@interstate/components/Box';
import { Grid } from '@interstate/components/Grid';
import { Typography } from '@interstate/components/Typography';
import { Tabs } from '@interstate/components/Tabs';
import { InterstateThemeProvider } from '@interstate/components/InterstateThemeProvider';
import { interstateThemeRegistry } from '@interstate/components/InterstateTheming/themes/interstateThemeRegistry';
import { QnA } from 'scenes/qna/qna';
import styled from 'styled-components';
import { MFPayload } from 'models/MFPayload';
import { ScannerScene } from 'scenes/scanner/scaner';
import { VehicleInfoUI } from '@checkpoint/common-components';

export default function TRAHomeScene(props: MFPayload) {
  return (
    <InterstateThemeProvider
      themeName="Interstate"
      applicationName="TRA Inspection"
      scopeName="TRA Inspection"
      themeRegistries={[interstateThemeRegistry]}
    >
      <StyledBox>
        <StyledGrid container spacing={2}>
          <Grid xs={12} sx={{ marginTop: '5px' }}>
            <Typography tag="span" variant="h1" color="base.color.blue.800">
              TRA Inspection
            </Typography>
          </Grid>
          <Grid xs={12}>
            <Typography tag="span" variant="h6" color="base.color.blue.800">
              {props.userDetails?.userName} {'-'}
              {props.userDetails?.emailId}
            </Typography>
          </Grid>
          <Tabs
            data-testid="default-data-testid"
            id="default-id"
            onActivate={function Ua() {}}
            tabs={[
              {
                component: <ScannerScene {...props} />,
                label: 'Scanner',
                value: 'Scanner',
                sx: { color: 'base.color.blue.800' },
                active: true
              },
              {
                component: (
                  <VehicleInfoUI
                    vehicleInfo={{
                      workOrderNumber: '1234567',
                      companyName: 'BENTLEY NAPLES',
                      manheimAccountNumber: '5263548',
                      vin: '4JGDA5HB4JB203752',
                      reading: '37703',
                      units: 'Miles',
                      shortDescription: '2018 Mercendes-Benz GLE 350',
                      interiorColor: 'Black',
                      exteriorColor: 'Grey'
                    }}
                    onContinue={() => {}}
                    onBack={() => {}}
                  />
                ),
                label: 'Vehicle Info',
                value: 'Vehicle Info',
                sx: { color: 'base.color.blue.800' }
              },
              {
                component: <QnA {...props} />,
                label: 'QnA',
                value: 'Questions',
                sx: { color: 'base.color.blue.800' }
              },
              {
                component: (
                  <div>
                    Photos content: Lorem ipsum dolor sit amet consectetur adipisicing elit. Mollitia corporis nostrum
                    repudiandae pariatur sed doloribus. Voluptatem, perspiciatis placeat nostrum nemo doloremque nobis
                    enim quibusdam nesciunt, nulla fugit ex, natus rerum?
                  </div>
                ),
                label: 'Photos',
                value: 'photos',
                sx: { color: 'base.color.blue.800' }
              },
              {
                component: (
                  <div>
                    Review content: Lorem ipsum dolor sit amet consectetur adipisicing elit. Mollitia corporis nostrum
                    repudiandae pariatur sed doloribus. Voluptatem, perspiciatis placeat nostrum nemo doloremque nobis
                    enim quibusdam nesciunt, nulla fugit ex, natus rerum?
                  </div>
                ),
                label: 'Review',
                value: 'review',
                sx: { color: 'base.color.blue.800' }
              }
            ]}
          />
        </StyledGrid>
      </StyledBox>
    </InterstateThemeProvider>
  );
}

const StyledBox = styled(Box)`
  background: white;
  min-height: 100vh;
`;

const StyledGrid = styled(Grid)`
  width: 90%;
  margin: auto !important;
`;
