import { MFPayload } from 'models/MFPayload';
import React, { useEffect } from 'react';

export const QnA = (props: MFPayload) => {
  useEffect(() => {
    window.QuestionsAndAnnouncements.renderQuestions({
      onStateChange: (newState: any) => {
        console.log('qna state changed', { ...newState });
      },
      clientApplicationName: 'Test App',
      rootElementId: 'questions-and-announcements-root',
      environment: props.appConfig?.qnaEnvironment || 'integration1',
      debug: true,

      announcerSource: 'inspector',
      username: 'Test',
      questionnaireHref: props.appConfig?.traFormUrl || 'https://integration1.api.manheim.com/forms/limited/latest',
      initialState: {
        announcements: [],
        questionResponses: []
      }
    });
  }, [props.appConfig?.qnaEnvironment, props.appConfig?.traFormUrl]);

  return <div id="questions-and-announcements-root" />;
};
